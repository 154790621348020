import {Suspense, useContext} from "react";
import {Navigate, RouterProvider, createBrowserRouter} from "react-router-dom";
import {ERoute} from "../configs/ERoute";
import AuthContext from "../../../provider/AuthProvider";
import {ProtectedRoute} from "./ProtectedRoute";
import {SignInPage} from "../../../pages/SignIn";
import {MainPage} from "../../../pages/Main";

const Routes = () => {
  const {user} = useContext(AuthContext);

  const routesForPublic = [
    {
      path: "/about",
      element: <div>About Us</div>,
    },
  ];

  const routesForAuthenticatedOnly = [
    {
      path: ERoute.MAIN,
      element: <ProtectedRoute />,
      children: [
        {
          path: "",
          element: <MainPage />,
        },
        {
          path: ERoute.CATEGORIES,
          element: <MainPage />,
        },
        {
          path: "*",
          element: <Navigate to={ERoute.MAIN} />,
        },
      ],
    },
  ];

  const routesForNotAuthenticatedOnly = [
    {
      path: ERoute.SIGN_IN,
      element: <SignInPage />,
    },
  ];

  const router = createBrowserRouter([
    ...routesForPublic,
    ...(user === null ? routesForNotAuthenticatedOnly : []),
    ...routesForAuthenticatedOnly,
  ]);

  return (
    <Suspense>
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default Routes;
