import React from "react";
import {AuthContextProvider} from "../../provider/AuthProvider";
import {ThemeContextProvider} from "../../provider/ThemeProvider";
import Routes from "./routes";
import "assets/less/styles.less";

export class App extends React.Component {
  render(): React.JSX.Element {
    return (
      <ThemeContextProvider>
        <AuthContextProvider>
          <React.Suspense>
            <Routes />
          </React.Suspense>
        </AuthContextProvider>
      </ThemeContextProvider>
    );
  }
}
