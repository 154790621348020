import {type AxiosResponse} from "axios";
import client from "../../client";
import {type Components, type Paths} from "../../../types/client";

export enum EAuthUrl {
  LOGIN = "/auth/login",
  REFRESH = "auth/refresh",
}

export const authLogin = async (
  body: Components.Schemas.Credentials,
): Promise<AxiosResponse<Components.Schemas.AuthenticationInfo>> => {
  return await client.post(EAuthUrl.LOGIN, body);
};

export const authRefresh = async (
  params: Paths.ApiAuthRefresh.Get.Parameters.Token,
): Promise<AxiosResponse<Components.Schemas.AuthenticationInfo>> => {
  return await client.get(EAuthUrl.REFRESH, {
    params,
  });
};
