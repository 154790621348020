import React from "react";
import ReactDOM from "react-dom/client";
import {App} from "./apps/sliderary";

const element = document.getElementById("root") as Element;

const root = ReactDOM.createRoot(element);

element.className = "educational-theme";

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
